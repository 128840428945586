import React from "react"
import styles from './logo.module.css'

type Props = {
  size?: number
}

const Logo = ({size}:Props) => (
  <div
    className={styles.container}
    style={{fontSize: `${size || 1}rem`}}
  >
    <div
    className={styles.rotateContainer}>
    <h1 className={styles.title}>Pill Punctual</h1>
    <div className={styles.background} />
    </div>
  </div>
)

export default Logo

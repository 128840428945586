import { Link } from "gatsby"
import React from "react"
import styles from './header.module.css'
import Logo from './logo'

const Header = () => (
  <header
    className={styles.header}
  >
    <div className={styles.innerHeader}>
      <Link className={styles.link} to="/">
        <Logo size={0.6}/>
      </Link>
    </div>
  </header>
)

export default Header

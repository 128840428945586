/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import './layout.css'
import style from './layout.module.css'

type Props = {
  home?: boolean
}
const Layout: React.FC<Props> = ({ home, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={style.container }>
      {!home && <Header />}
      <main className={style.innerContainer}>{children}</main>
      <footer className={style.footer}>
        <div className={style.links}>
          <Link className={style.link} to="/support/">Support</Link>
          <Link className={style.link} to="/tos/">Terms of Service</Link>
          <Link className={style.link} to="/privacy/">Privacy</Link>
        </div>
        <div className={style.copyright}>
          © {new Date().getFullYear()} Pill Punctual
        </div>
      </footer>
    </div>
  )
}

export default Layout
